import '../scss/site.scss';

$(function () {

    $(window).on('pageshow', function () {
        hideSpinner();
    });

    $("#modErrorMessage").modal();
    $("#modWarningMessage").modal();
    $("#modInformationMessage").modal();

    $(':submit:not(".nospin"), a:not(".nospin, .dropdown-toggle"):not([target="_blank"]), button.spin').on("click", function (event) {
        $(".modal").modal('hide');
        showSpinner();
    });

    $('.custom-control > :checkbox').on("change", function (event) {
        if (this.checked) {
            this.value = 'true';
        } else {
            this.value = 'false';
        }
    });

    $('.ibis-menu-select').on('change', function (event) {
        showSpinner();
        const url = $(this).val();
        window.location.href = url;
    });
});

function showSpinner() {
    $('#spinner').modal({ backdrop: 'static', keyboard: false, show: true })
}

function hideSpinner() {
    $('#spinner').modal('hide');
}